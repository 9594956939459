<template>
  <div>
    <v-toolbar>
      <v-toolbar-title
        >{{ $t('ITEM.VIEW') }} {{ $t('NOTIFICATIONS.ITEM') }}</v-toolbar-title
      >
      <v-spacer />
      <v-btn depressed color="error" @click="remove">
        {{ $t('BUTTON.DELETE') }}
      </v-btn>
      <confirm-dialog ref="confirm"></confirm-dialog>
      <v-btn class="ml-2" dark color="indigo" @click="$router.go(-1)">
        {{ $t('BUTTON.BACK') }}
      </v-btn>
      <v-btn
        class="ml-2"
        color="primary"
        :disabled="disabledEditButton"
        @click="
          $router.push({
            name: 'notifications.edit',
            params: { id: id },
          })
        "
      >
        {{ $t('BUTTON.EDIT') }}
      </v-btn>
    </v-toolbar>

    <v-sheet class="pa-5" tile>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12">
            <v-card class="mt-3">
              <v-card-title class="font-weight-regular">{{
                $t('NOTIFICATIONS.GENERAL_INFORMATION')
              }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <general-information-form v-model="notification" readonly />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <recipients-filters :filters="notification.filter" readonly />
          </v-col>

          <v-col cols="12">
            <recipients
              class="elevation-1"
              v-if="notification.filter"
              v-model="notification.user_ids"
              :items="notification.users"
              readonly
            />
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { NotificationsService } from '@/services/notifications.service';
import Recipients from '../components/RecipientsList.vue';
import RecipientsFilters from '../components/RecipientsFilters.vue';
import GeneralInformationForm from '../components/forms/GeneralInformationForm.vue';

export default {
  name: 'NotificationShowPage',

  components: {
    Recipients,
    RecipientsFilters,
    GeneralInformationForm,
    ConfirmDialog: () => import('@/components/ConfirmDialog'),
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    valid: false,
    notification: {
      filter: {},
      users: [],
      user_ids: [],
      is_editable: true,
    },
    recipients: [],
  }),

  computed: {
    disabledEditButton() {
      return !this.notification.is_editable;
    },
  },

  mounted() {
    NotificationsService.getById(this.id).then(({ data }) => {
      this.notification = data;
    });
  },

  methods: {
    remove() {
      this.$refs.confirm
        .open(this.$t('NOTIFICATIONS.REALLY_WANT_TO_REMOVE'), null, {
          cancelBtnText: this.$t('BUTTON.CANCEL'),
          agreeBtnText: this.$t('BUTTON.DELETE'),
          agreeBtnColor: 'error',
        })
        .then((confirm) => {
          if (confirm) {
            NotificationsService.remove(this.id)
              .then(({ data }) => {
                this.$emit('updated', data.data);
                this.$store.commit('snackbar/setSnack', {
                  status: 'success',
                  text: this.$t('MESSAGE.SUCCESS'),
                });

                this.$router.push({ name: 'notifications' });
              })
              .catch((response) => {
                this.handleErrorsByResponse(response);
              });
          }
        });
    },
  },
};
</script>
